import React, { createContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import Home from './Home';
import RiskHome from './RiskHome';
import ROI from './ROIHome';
import Demo from './Demo2'

const Workflow = () =>{
    const [workflow, setWorkflow] = useState(null);


    if(!workflow){
        return <Home setWorkflow={setWorkflow}/>
    }

    if(workflow === 'risk'){
        return <RiskHome />
    }

    if(workflow === 'roi'){
        return <ROI />
    }

    return ""
     
}


export default Workflow;