import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import Demo3 from './Demo3';

const ComparisonWrapper = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Raleway', sans-serif;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: auto 150px 150px; /* Fixed width for the last two columns */
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
`;

const HeaderText = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color:${props => props.color === 'other' ? '#4a4a4a' : '#2D70E2'};

  &:first-child {
    text-align: left;
  }
`;

const FeatureRow = styled.div`
  display: grid;
  grid-template-columns: auto 150px 150px; /* Matches header alignment */
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  border-radius: 8px;
`;

const FeatureContentWrapper = styled.div`
  display: flex;
  gap: 15px;
`;

const FeatureIconWrapper = styled.div`
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F2F2F2;
  border: 1px solid #ddd;
  border-radius: 5px;
  color:#666D79;
  font-size:16px;
  
`;

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FeatureTitle = styled.div`
  font-weight: bold;
  color: #2A3039;
`;

const FeatureDescription = styled.div`
  color: #666D79;
  font-size: 14px;
`;

const StatusColumn = styled.div`
  text-align: center;
`;

const CheckIcon = styled(Icon)`
  color: #2D70E2; /* Green */
  font-size: 20px !important;
`;

const PartialText = styled.div`
  color: #b5b5b5;
  font-size: 14px;
`;

const FeatureWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ddd;
`

const Top = styled.div`
  margin-bottom: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const T2 = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 5px;
  color:#B3B3B3;
`

const Title = styled.div`
  font-size: 24px;
  color:#2A3039;
  font-weight:600;
`

const Description = styled.div`
  margin-top: 30px;
  color: #666D79;
  font-size: 14px;
  max-width: 600px;
`

const Blue = styled.span`
  color: #2D70E2;

  `

const ComparisonComponent = () => {
  const data = [
    {
      feature: 'HR Analytics Integration',
      description:
        'Traditional tools offer siloed metrics such as engagement scores, which are difficult to tie directly to business outcomes. Innerlogic provides a seamless integration of culture analytics with revenue, retention, and productivity metrics.',
      innerlogic: 'check',
      traditional: 'partial',
      icon: 'chart bar', // Icon for HR Analytics
    },
    {
      feature: 'Proactive & Predictive Insights',
      description:
        'Innerlogic uses AI to forecast turnover, productivity dips, and engagement trends, enabling proactive decision-making. Traditional tools rely on historical, descriptive reports.',
      innerlogic: 'check',
      traditional: null,
      icon: 'chart line', // Icon for Predictive Insights
    },
    {
      feature: 'Real-Time Feedback & Pulse Surveys',
      description:
        'Innerlogic captures continuous feedback and analyzes evolving sentiment in real time, while traditional tools focus on static, annual surveys.',
      innerlogic: 'check',
      traditional: 'partial',
      icon: 'clock outline', // Icon for Real-Time Feedback
    },
    {
      feature: 'Actionable ROI Metrics',
      description:
        'Innerlogic links HR initiatives to tangible ROI metrics such as cost savings, revenue impact, and productivity gains. Traditional tools struggle with disconnected metrics.',
      innerlogic: 'check',
      traditional: null,
      icon: 'dollar sign', // Icon for ROI
    },
    {
      feature: 'Natural Language Processing (NLP)',
      description:
        'Innerlogic analyzes qualitative feedback from sources like Slack and performance reviews to identify cultural themes. Traditional tools lack advanced AI capabilities.',
      innerlogic: 'check',
      traditional: null,
      icon: 'comments', // Icon for NLP
    },
    {
      feature: 'Alignment with Business Objectives',
      description:
        'Innerlogic ensures HR analytics align with business goals like customer satisfaction and market adaptability. Traditional tools often fail to connect HR initiatives to business outcomes.',
      innerlogic: 'check',
      traditional: 'partial',
      icon: 'bullseye', // Icon for Alignment
    },
  ];

  return (
    <ComparisonWrapper>

      <Top>
        <T2>Comparison</T2>
        <Title>Why choose <Blue>innerlogic</Blue> over other engagement surveys?</Title>
        <Description>
          Innerlogic's AI-driven platform offers a modern approach to culture analytics, providing actionable insights that drive business outcomes.
        </Description>
      </Top>



      {/* Headers */}
      <HeaderRow>
        <HeaderText></HeaderText> {/* Empty space for the feature column */}
        <HeaderText>Innerlogic</HeaderText>
        <HeaderText color={'other'}>Traditional Tools</HeaderText>
      </HeaderRow>

      <FeatureWrapper>
         {/* Features */}
      {data.map((row, index) => (
        <FeatureRow key={index}>
          <FeatureContentWrapper>
            <FeatureIconWrapper>
              <Icon name={row.icon} style={{margin:0,marginTop:-2}}/>
            </FeatureIconWrapper>
            <FeatureContent>
              <FeatureTitle>{row.feature}</FeatureTitle>
              <FeatureDescription>{row.description}</FeatureDescription>
            </FeatureContent>
          </FeatureContentWrapper>
          <StatusColumn>
            {row.innerlogic === 'check' ? <CheckIcon name="check circle" /> : '—'}
          </StatusColumn>
          <StatusColumn>
            {row.traditional === 'check' ? (
              <CheckIcon name="check circle" />
            ) : row.traditional === 'partial' ? (
              <PartialText>Partial</PartialText>
            ) : (
              '—'
            )}
          </StatusColumn>
        </FeatureRow>
      ))}
      </FeatureWrapper>


      <Demo3/>
     
    </ComparisonWrapper>
  );
};

export default ComparisonComponent;
