import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";

import SlackLandingPage from "./LandingPage";
import StartSync from "./slackConnect/StartSync";
import UserSyncPreview from "./slackConnect/SlackUsers";
import SlackUsers from "./slackConnect/SlackEmployees";
import SlackChannel from "./slackConnect/SlackChannel";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_EMPLOYEE_SLACK,
  SG_FETCH_EMPLOYEE_SLACK,
  SG_UPDATE_EMPLOYEE_SLACK,
} from "constants/actions";

const SlackApp = () => {
  const dispatch = useDispatch();
  const [slackUsers, setSlackUsers] = useState([]);
  const [internalUsers, setInternalUsers] = useState([]);
  const [mappings, setMappings] = useState([]);

  const { get_auth, get_organizations, get_employee, get_employee_slack } =
    useSelector(
      (state) => ({
        get_errors: state.errors,
        get_auth: state.auth,
        get_organizations: state.organizations,
        get_employee: state.employees,
        get_employee_slack: state.employee_slack,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_employee?.userEmp?.id) {
      dispatch({
        type: SG_FETCH_EMPLOYEE_SLACK,
        payload: {
          id: get_employee?.userEmp?.id,
        },
      });
    }
  }, [dispatch, get_employee]);



  const handleChangeMappings = (newMappings) => {
    setMappings(newMappings);
  };

  const handleConfirm = () => {
    // Handle the confirm and sync action
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: "20px", minHeight: "10vh", marginTop: 30 }}>
        <SlackLandingPage />
      </div>

      {/* Steps After user is connected with Slack */}
      <StartSync />

      {/* Preview of the user sync */}
      <UserSyncPreview
        slackUsers={slackUsers}
        internalUsers={internalUsers}
        mappings={mappings}
        onChangeMappings={handleChangeMappings}
        onConfirm={handleConfirm}
      />

      {/* Slack Users */}
      <SlackUsers />

      {/* Slack Channel */}
      <SlackChannel />
    </ThemeProvider>
  );
};

export default SlackApp;
