import React, { createContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import IconWithBackground from "new_components/molecules/IconWithBackground";
import Icon from "new_components/atoms/Icon";

const Home = ({setWorkflow}) => {

    return (
    <Container>
            <Title>
                Workflow AI
            </Title>

            <Description>
                Use AI to guide you through your data. Go from data to insights in minutes using one of our expert built, AI guided workflows.
            </Description>

            <CardContainer>
                <Card>
                    <IconWithBackground name={ICON_SET.aiIcon} bgColor={'#EBF1FD'} />
                    <CardTitile>Risk Assessment</CardTitile>
                    <CardDescription>
                        Analzye where groups of lower scores are present, and identify the key factors that are driving the risk.
                    </CardDescription>
                    <Action onClick={()=>setWorkflow('risk')}>Select workflow <Icon name={ICON_SET.chevronRight} bgColor={'#2D70E2'} size={16}/></Action>
                </Card>

                <Card>
                    <IconWithBackground name={ICON_SET.userIcon} bgColor={'#EBF1FD'} />
                    <CardTitile>ROI Analysis</CardTitile>
                    <CardDescription>
                        Analzye where groups of lower scores are present, and identify the key factors that are driving the risk.
                    </CardDescription>
                    <Action onClick={()=>setWorkflow('roi')}>Select workflow <Icon name={ICON_SET.chevronRight} bgColor={'#2D70E2'} size={16}/></Action>
                </Card>

                <Card>
                    <IconWithBackground name={ICON_SET.layers} bgColor={'#EBF1FD'} />
                    <CardTitile>Thematic Analysis</CardTitile>
                    <CardDescription>
                        Analzye where groups of lower scores are present, and identify the key factors that are driving the risk.
                    </CardDescription>
                    <Action>Select workflow <Icon name={ICON_SET.chevronRight} bgColor={'#2D70E2'} size={16}/></Action>
                </Card>
            </CardContainer>
    </Container>        
    )
}

export default Home;

const Container = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    font-family: 'Raleway', sans-serif;
    margin-top:20px;
    padding:20px;
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:10px;
`

const Description = styled.div`
    margin-bottom:20px;
    max-width:700px;
`

const CardContainer = styled.div`
    display:flex;
    width:100%;
    flex-wrap:wrap;
`

const Card = styled.div`
    width: calc(33% - 20px);
    height: 225px;
    background-color: #F8FAFF;
    border-radius:5px;
    padding:20px;
    margin-right:20px;
    margin-bottom:20px;
    cursor:pointer;
`

const CardTitile = styled.div`
    color:#2A3039;
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
    margin-top:20px;
`
const CardDescription = styled.div`
    color:#706C85;
    font-size:12px;
`

const Action = styled.div`
    color:#2D70E2;
    font-size:14px;
    font-weight:600;
    display:flex;
    align-items:center;
    margin-top:20px;
`