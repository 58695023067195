import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const ComparisonWrapper = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Raleway', sans-serif;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: auto 150px 150px 150px 150px; /* Fixed width for competitors */
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
`;

const HeaderText = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => (props.color === 'innerlogic' ? '#2D70E2' : '#666D79')};

  &:first-child {
    text-align: left;
  }
`;

const FeatureRow = styled.div`
  display: grid;
  grid-template-columns: auto 150px 150px 150px 150px; /* Matches header alignment */
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
`;

const FeatureContent = styled.div`
  font-weight: bold;
  color: #2A3039;
`;

const StatusColumn = styled.div`
  text-align: center;
`;

const CheckIcon = styled(Icon)`
  color: #2D70E2; /* Blue for Innerlogic */
  font-size: 20px !important;
`;

const CrossIcon = styled(Icon)`
  color: #b5b5b5; /* Grey for non-features */
  font-size: 20px !important;
`;

const CompetitorComparison = () => {
  // Features with data
  const data = [
    {
      feature: 'Descriptive Analytics',
      innerlogic: 'check',
      cultureamp: 'check',
      qualtrics: 'check',
      glint: 'check',
    },
    {
      feature: 'AI Driven Surveys',
      innerlogic: 'check',
      cultureamp: null,
      qualtrics: null,
      glint: null,
    },
    {
      feature: 'Predictive and Prescriptive Analytics',
      innerlogic: 'check',
      cultureamp: null,
      qualtrics: null,
      glint: null,
    },
    {
      feature: 'Real Time Sentiment Analysis',
      innerlogic: 'check',
      cultureamp: 'partial',
      qualtrics: null,
      glint: 'partial',
    },
    {
      feature: 'AI Optimized Workflows',
      innerlogic: 'check',
      cultureamp: null,
      qualtrics: null,
      glint: null,
    },
    {
      feature: 'Instant Reporting + Presentations',
      innerlogic: 'check',
      cultureamp: null,
      qualtrics: null,
      glint: null,
    },
    {
      feature: 'ROI Focused Analysis',
      innerlogic: 'check',
      cultureamp: null,
      qualtrics: null,
      glint: null,
    },
  ];

  // Sort features by number of checks
  const sortedData = data.sort((a, b) => {
    const countChecks = (row) =>
      [row.innerlogic, row.cultureamp, row.qualtrics, row.glint].filter(
        (value) => value === 'check'
      ).length;
    return countChecks(b) - countChecks(a);
  });

  return (
    <ComparisonWrapper>
      {/* Title and Description */}
      <div style={{ marginBottom: '60px' }}>
        <div style={{ fontSize: '24px', color: '#2A3039', fontWeight: 'bold' }}>
         How does innerlogic compare?
        </div>
        <div style={{ marginTop: '10px', color: '#666D79', fontSize: '14px', maxWidth: '600px' }}>
          Innerlogic offers superior AI-driven culture analytics compared to traditional platforms, ensuring actionable insights and measurable business outcomes.
        </div>
      </div>

      {/* Headers */}
      <HeaderRow>
        <HeaderText></HeaderText> {/* Empty space for feature column */}
        <HeaderText color="innerlogic">Innerlogic</HeaderText>
        <HeaderText>CultureAmp</HeaderText>
        <HeaderText>Qualtrics</HeaderText>
        <HeaderText>Glint</HeaderText>
      </HeaderRow>

      {/* Feature Rows */}
      {sortedData.map((row, index) => (
        <FeatureRow key={index}>
          <FeatureContent>{row.feature}</FeatureContent>
          <StatusColumn>
            {row.innerlogic === 'check' ? (
              <CheckIcon name="check circle" />
            ) : (
              <CrossIcon name="times circle outline" />
            )}
          </StatusColumn>
          <StatusColumn>
            {row.cultureamp === 'check' ? (
              <CheckIcon name="check circle" />
            ) : row.cultureamp === 'partial' ? (
              <div style={{ color: '#b5b5b5', fontSize: '14px' }}>Partial</div>
            ) : (
              <CrossIcon name="times circle outline" />
            )}
          </StatusColumn>
          <StatusColumn>
            {row.qualtrics === 'check' ? (
              <CheckIcon name="check circle" />
            ) : row.qualtrics === 'partial' ? (
              <div style={{ color: '#b5b5b5', fontSize: '14px' }}>Partial</div>
            ) : (
              <CrossIcon name="times circle outline" />
            )}
          </StatusColumn>
          <StatusColumn>
            {row.glint === 'check' ? (
              <CheckIcon name="check circle" />
            ) : row.glint === 'partial' ? (
              <div style={{ color: '#b5b5b5', fontSize: '14px' }}>Partial</div>
            ) : (
              <CrossIcon name="times circle outline" />
            )}
          </StatusColumn>
        </FeatureRow>
      ))}
    </ComparisonWrapper>
  );
};

export default CompetitorComparison;
