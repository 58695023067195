import React, { useEffect, useState } from "react";

import {
  Card,
  Heading,
  Paragraph,
  Button,
  Spacer,
  Table,
  Overlay,
} from "ProtoType/Slack/styles/Common";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_ORG_SLACK,
  SG_UPDATE_EMPLOYEE_SLACK,
  SG_DELETE_ORG_SLACK,
} from "constants/actions";

const SlackConnectView = () => {
  const dispatch = useDispatch();
  const [organizationInfo, setOrganizationInfo] = useState(null);
  const [slackMainEmployee, setSlackMainEmployee] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORG_SLACK,
    });
  }, [dispatch]);

  const { get_employees, get_employee_slack, get_org_slack } = useSelector(
    (state) => ({
      get_employee_slack: state.employee_slack,
      get_employees: state.employees,
      get_org_slack: state.org_slack,
    }),
    shallowEqual
  );

  useEffect(() => {
    setOrganizationInfo(get_org_slack?.org_slack?.[0]);
    setSlackMainEmployee(false);
    // Simulate a loading state
    if (get_org_slack?.org_slack?.[0]?.id) {
      setTimeout(() => {
        if (
            Number(get_employee_slack?.user_slack?.employee) === Number(get_employees?.userEmp?.id)
          ) {
            if (get_employee_slack?.user_slack?.slack_token) {
              setSlackMainEmployee(true);
            }
          }
      }, 3000); // Hide overlay after 3 seconds
    }
  }, [get_org_slack, get_employee_slack, get_employees]);


  const defaultUrl = "https://slack-bot.innerlogic.ca";
  const [hashedEmployeeId, setHashedEmployeeId] = useState(null);
  const [callBackUrl, setCallBackUrl] = useState(
    `${defaultUrl}/slack/oauth/callback`
  );

  useEffect(() => {
    if (window.location.hostname === "app.innerlogic.com") {
      setCallBackUrl("https://slack-bot.innerlogic.ca/slack/oauth/callback");
    }
    if (
      window.location.hostname === "stg-app.innerlogic.com" ||
      window.location.hostname === "demo.innerlogic.com"
    ) {
      setCallBackUrl(
        "https://stg-slack-bot.innerlogic.ca/slack/oauth/callback"
      );
    }
  }, []);

  useEffect(() => {
    setSlackMainEmployee(false);
    if(!organizationInfo?.access_token) {
        setSlackMainEmployee(true);
        return
    }
    if (get_employee_slack?.user_slack) {
        if (
            get_employee_slack?.user_slack?.employee === get_employees?.userEmp?.id
          ) {
            if (get_employee_slack?.user_slack?.employee?.slack_token) {
              setSlackMainEmployee(true);
            }
          }
    } else {
        // aacount not added
        setSlackMainEmployee(true);
    }
    
  }, [get_employee_slack, get_employees, organizationInfo]);

  function onRemove() {
    // dispatch({
    //     type: SG_DELETE_ORG_SLACK,
    //   });
    // Normally: fetch('/api/slack/remove')
    alert(
      "Removing Slack app... steps have to be decided it would remove all employees from slack sync"
    );
  }

  return (
    <Card>
        {!slackMainEmployee && (
        <Overlay>
          <div>
            <p>Processing, please wait...only the main employee can manage this page</p>
          </div>
        </Overlay>
      )}
     


      <Heading>Slack App Settings</Heading>
      <Paragraph>
        Manage your Slack integration from here. You can re-run the user sync if
        your user lists have changed, or remove the Slack application entirely.
      </Paragraph>
      <Spacer height="20px" />

      <a
        href={`https://slack.com/oauth/v2/authorize?client_id=1231925461201.7537808644229&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,commands,dnd:read,groups:history,groups:read,im:history,im:read,im:write,incoming-webhook,users.profile:read,users:read,mpim:read&user_scope=users.profile:read,groups:read,channels:read,mpim:read&redirect_uri=${callBackUrl}&state=${hashedEmployeeId}`}
      >
        {organizationInfo?.access_token ? (
          <Button
            disabled={slackMainEmployee}
            style={{
              background: "#FFF",
              color: "#6F42C1", // Purple color
              borderColor: "#6F42C1",
            }}
          >
            Resync Slack
          </Button>
        ) : (
          <Button
            disabled={slackMainEmployee}
            style={{
              background: "#FFF",
              color: "#28A745", // Green color
              borderColor: "#28A745",
            }}
          >
            Add to Slack
          </Button>
        )}
      </a>

      <Button
        disabled={slackMainEmployee}
        style={{ background: "#FFF", color: "#DC3545", borderColor: "#DC3545" }}
        onClick={onRemove}
      >
        Disconnect Slack
      </Button>

      <Spacer height="40px" />
    </Card>
  );
};

export default SlackConnectView;
