// CopilotContext.js
import React, { createContext, useState, useEffect } from 'react';

export const CopilotContext = createContext(null);

export function CopilotProvider({ children }) {
  // Basic chat states
  const [messages, setMessages] = useState([]);      // entire conversation
  const [phase, setPhase] = useState('idle');        // 'idle'|'deciding'|'decided'|'executing'
  const [decisionProcess, setDecisionProcess] = useState(null);
  const [promptText, setPromptText] = useState('');

  /**
   * -------------------------------
   * 1) Call the OpenAI Chat API
   * -------------------------------
   * This function sends a message to the OpenAI API using fetch and
   * processes the streaming response. It appends tokens to the last
   * "assistant" message in our local state.
   */
  async function callOpenAI(userPrompt) {
    try {
      // Update state: user message
      // setMessages((prev) => [...prev, { role: 'user', content: userPrompt }]);

      // Start streaming (append new assistant message)
      setMessages((prev) => [...prev, { role: 'assistant', content: '' }]);
      setPhase('executing');

      // Make request to OpenAI with streaming enabled
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`,
        },
        body: JSON.stringify({
          model: 'gpt-4o', 
          messages: [
            // Optionally add a system message here for context, e.g.:
            // { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: userPrompt },
          ],
          stream: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`OpenAI API responded with status ${response.status}`);
      }

      // Start reading the response as a stream
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;

      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunkValue = decoder.decode(value, { stream: true });
        const lines = chunkValue.split('\n').filter(line => line.trim() !== '');

        for (const line of lines) {
          // The OpenAI "data: [DONE]" event signals the end of the stream
          if (line.trim() === 'data: [DONE]') {
            done = true;
            break;
          }

          // Each line starts with "data: "
          const jsonStr = line.replace(/^data:\s*/, '');
          try {
            const parsed = JSON.parse(jsonStr);
            const token = parsed?.choices?.[0]?.delta?.content ?? '';

            // Only append if there's actual content
            if (token) {
              setMessages((prev) => {
                // Append to the last assistant message
                const lastIndex = prev.length - 1;
                if (lastIndex < 0) return prev;
                const lastMessage = prev[lastIndex];

                // If it's not an assistant message, create one
                if (lastMessage.role !== 'assistant') {
                  return [...prev, { role: 'assistant', content: token }];
                } else {
                  // Otherwise, append token to existing assistant content
                  const updated = [...prev];
                  updated[lastIndex] = {
                    ...lastMessage,
                    content: lastMessage.content + token,
                  };
                  return updated;
                }
              });
            }
          } catch (err) {
            console.error('Error parsing stream data:', err);
          }
        }
      }

      // End of stream
      setPhase('idle');
    } catch (error) {
      console.error('Error calling OpenAI:', error);
      setPhase('idle');
    }
  }

  /**
   * -------------------------------
   * 2) Send prompt to OpenAI
   * -------------------------------
   * This is just a convenience wrapper that you can call from your UI.
   */
  function sendPromptToOpenAI() {
    if (!promptText.trim()) {
      return;
    }
    callOpenAI(promptText);
    setPromptText('');
  }

  /**
   * -------------------------------
   * 3) Execute next step
   * -------------------------------
   * If you want to preserve the idea of "executing" a named process (like you had before),
   * you can still do so. This example just calls callOpenAI with some extra data.
   */
  function executeProcess({ prompt, processName }) {
    // In your previous code, this triggered the server with "function: processName".
    // You could pass that to the OpenAI prompt in some structured way, e.g.:
    const combinedPrompt = `Please execute the process "${processName}". User prompt: ${prompt}`;
    callOpenAI(combinedPrompt);
  }

  /**
   * -------------------------------
   * 4) Clear all conversation data
   * -------------------------------
   */
  function clearMessages() {
    setMessages([]);
    setDecisionProcess(null);
    setPhase('idle');
  }

  /**
   * -------------------------------
   * 5) Start "Risk Assessment"
   * -------------------------------
   * Example function to demonstrate adding a custom role message to the conversation.
   */
  const startRisk = () => {
    console.log('Risk Assessment');
    setMessages((prev) => [...prev, { role: 'function', type: 'Risk Assessment' }]);
  };

  /**
   * -------------------------------
   * 6) Provide all context values
   * -------------------------------
   */
  const value = {
    messages,
    phase,
    decisionProcess,
    executeProcess,
    clearMessages,
    promptText,
    setPromptText,
    startRisk,
    setMessages,

    // New
    sendPromptToOpenAI,
  };

  return (
    <CopilotContext.Provider value={value}>
      {children}
    </CopilotContext.Provider>
  );
}
