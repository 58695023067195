import {
  GET_SLACK_USER_LIST,
  GET_SLACK_CHANNEL_LIST,
  PENDING_SLACK_CALL,
  CLEAR_SLACK_CALL,
  CLEAR_SELECTED_SLACK_CALL,
  LINK_SLACK_ID_TO_EMPLOYEE_ID,
  DELETE_SLACK_ID_TO_EMPLOYEE_ID,
  UPDATE_SLACK_CHANNEL_LIST,
} from "../constants/actions";

const initialState = {
  slackUsers: [],
  slackChannels: [],
  pageNo: 1,
  nextCursor: null,
  previousCursor: null,
  totalPages: 1,
  loading: false,
  error: null,
  slackChannelUpdated: false,
  slackUserUpdated: false,
};

export default function slack_calls(state = initialState, action) {
  switch (action.type) {
    case PENDING_SLACK_CALL:
      return {
        ...state,
        loading: true,
      };
    case GET_SLACK_USER_LIST:
      return {
        ...state,
        pageNo: state.pageNo,
        slackEmployees: action.payload.members,
        nextCursor: action.payload.next_cursor,
        previousCursor: action.payload.previous_cursor,
        totalPages: action.payload.total_pages,
        loading: false,
      };
    case GET_SLACK_CHANNEL_LIST:
      return {
        ...state,
        slackChannels: action.payload,
        loading: false,
      };
    case UPDATE_SLACK_CHANNEL_LIST:
      return {
        ...state,
        slackChannelUpdated: Math.random(),
        loading: false,
      };
    case CLEAR_SLACK_CALL:
      return {
        ...state,
        slackEmployees: [],
        slackChannels: [],
        loading: false,
      };
    case CLEAR_SELECTED_SLACK_CALL:
      return {
        ...state,
        slackEmployees: [],
        loading: false,
      };
    case LINK_SLACK_ID_TO_EMPLOYEE_ID:
      return {
        ...state,
        loading: false,
        slackUserUpdated: Math.random(),
      };
    case DELETE_SLACK_ID_TO_EMPLOYEE_ID:
      return {
        ...state,
        loading: false,
        slackUserUpdated: Math.random(),
      };
    default:
      return state;
  }
}