import React, { createContext, useState, useEffect,useContext,useRef } from 'react';
import styled,{keyframes} from 'styled-components';
import { CopilotContext, CopilotProvider } from './CopilotContext';
import Risk from './Functions/Risk';
import {formatElements} from './Functions/parseText'; 
import AICard from './Components/AICard';
import Suggestions from './Components/Suggestions'; 
import FollowUpCard from './Components/FollowUpCard';
import {RunThematic} from './Functions/ThematicsAnalysis';
import Filters from './Filters'

const convo_id = 'my-convo-id';


function RiskHomeComponent(){
    const scrollRef = useRef(null);
    const endOfMessagesRef = useRef(null);
      const { messages,
         decideNextStep,
         decisionProcess,
          clearMessages,
          setPromptText,
          promptText,
          executeProcess,
          startRisk,
          setMessages,
          phase } = useContext(CopilotContext);

    const [started, setStarted] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [suggestionHistory, setSuggestionHistory] = useState([]);

    const [followUp, setFollowUp] = useState(false);

    useEffect(() => {
        if(phase =='idle' && prompt){
            console.log(prompt)
            setStarted(true);
            setMessages((prev) => [...prev, { role: 'function', content:'Running Risk Assessment' }]);
        }
    }, [prompt])

    useEffect(()=>{
        if(followUp!==false){
            setMessages((prev) => [...prev, { role: 'follow_up', content:'Running thematic analysis on feedback'}]);
        }
    },[followUp])

    const registerHistory = (data,prompt,analysisBreakdown) => {
        setPrompt({prompt:prompt,conversationId:convo_id,processName:'my-process'});
        setSuggestionHistory((prev) => [...prev,{data,analysisBreakdown}]);
    }

     useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
          }
      }, [messages]);



    if(!started){
        return (
            <Container>
                <Risk 
                    intro={true}
                    onClick={({data,prompt,analysisBreakdown}) =>registerHistory(data,prompt,analysisBreakdown)}/>
            </Container>
        )
    }

    return (
        <Container ref={scrollRef}>
            <Title>Risk Assessment Workflow</Title>

            <Content >
                <MessageContainer>
                {messages.map((msg, i) => {
                    if(msg.role === 'function'){
                        return <AICard start={()=>executeProcess(prompt)}/>
                    }

                    if(msg.role === 'follow_up'){
                        return <FollowUpCard start={()=>RunThematic(executeProcess,followUp)}/>
                    }

                    const new_index = messages.filter(f=>f.role == 'assistant').length - 1;
                    const retrievedSuggestion = suggestionHistory[suggestionHistory.length -1];

                        return (
                        <Bubble key={i} role={msg.role}>
                            {formatElements(msg.content)}
                            {((phase == "idle" && i+1 == messages.length) && retrievedSuggestion) && <Suggestions data={retrievedSuggestion} selectFollowUp={(d)=>setFollowUp({data:retrievedSuggestion,selection:d})}/> }
                        </Bubble> )
                    }
                    )}
                </MessageContainer>

                <SettingsArea>
                    <Risk 
                        intro={false}
                        onClick={({data,prompt,analysisBreakdown}) =>registerHistory(data,prompt,analysisBreakdown)}/>
                </SettingsArea>
            </Content>
            <div ref={endOfMessagesRef} />
            
        </Container>
    )
}



export default function RiskHome() {
  return (
    <CopilotProvider>
      <RiskHomeComponent />
    </CopilotProvider>
  );
}

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;



const Container = styled.div`
    margin-top:20px;
    font-family: 'Raleway', sans-serif;
    padding:20px;
    width:100%:
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:10px;
`

const Content = styled.div`
    display:flex;
    flex-direction: row;
    margin-top:20px;
    width:100%:

`

const MessageContainer = styled.div`
    width:calc(100% - 300px);
    padding-bottom:150px;
`

const Bubble = styled.div`
  background-color: ${(props) => (props.role === 'assistant' ? '#F8FAFF' : '#dcecff')};
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 6px;
  max-width: 100%;
  align-self: ${(props) => (props.role === 'assistant' ? 'flex-start' : 'flex-end')};
      opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
`;

const SettingsArea = styled.div`
    width:300px;
    padding:20px;
    position:fixed;
    right:0;
`
