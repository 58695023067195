// This file is designed to be used as the logic center for
// fetching data and hydrating the redux store.
// It is designed to fetch the bare minimum data required to run each of the routes below
// and to hydrate the redux store with that data.

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  SET_FILTERED_DATA,
  SET_FILTERED_LAST_DATA,
  SET_DATA_DATES,
  SET_RAW_DATA,
  SET_QUESTIONS,
  SET_CORE_DATA,
  SET_NAVIGATION,
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
  SG_GET_MANAGER_USERS,
} from "constants/actions";
import LogAnimation from "../LogAnimation";
import { Dimmer } from "semantic-ui-react";
import SurveyVersions from "reports/Audit/VersionControl/index";
import { SURVEY_INFO } from "constants/surveys";
import { useNavigate } from "react-router-dom";

// Calculations
import {
  checkPersonality,
  convert_personality_to_categories,
} from "../calculations";

import {
  filter_data,
  get_dates,
  get_most_recent,
  sort_historical_data,
  build_default_State,
  get_last_responses,
  // calculate_table_data,
} from "reports/Audit/data_navigation/calculate_data";

const personality_names = [
  ["Extraversion", "Extraverted"],
  ["Conscientiousness", "Conscientiousness"],
  ["Agreeableness", "Agreeable"],
  ["Neuroticism", "Neurotic"],
  ["Openess", "Open"],
];

const personality_categories = personality_names.map((item) => {
  return {
    id: item[0],
    name: item[0],
    options: [
      { id: "0", name: "Least " + item[1], color: "blue" },
      { id: "1", name: "Moderately " + item[1], color: "red" },
      { id: "2", name: "Most " + item[1], color: "green" },
    ],
    priority: "personality",
    subtitle: item,
    demographic: true,
  };
});

// Using prioritiesToFilterStructure and surveyStructureFiltered
// find the matching priorities and from the surveyStructureFiltered?.categories?.id
// and filter out any of the options that are not in the prioritiesToFilterStructure?.values array.
// If the prioritiesToFilterStructure?.values array is empty, then return the surveyStructureFiltered
// as is.
const filterSurveyStructure = (
  surveyStructureFiltered,
  prioritiesToFilterStructure
) => {
  if (prioritiesToFilterStructure?.length === 0) {
    return surveyStructureFiltered;
  }
  const filteredCategories = surveyStructureFiltered?.[0]?.categories?.map(
    (category) => {
      const permissionsToFilter = prioritiesToFilterStructure?.find(
        (priority) => {
          return priority?.priority === category?.id;
        }
      );
      if (!permissionsToFilter || category.priority == "primary") {
        return category;
      }

      const filteredOptions = category?.options?.filter((option) => {
        return permissionsToFilter?.values?.includes(option?.id);
      });

      const updatedCategory = { ...category };
      updatedCategory.options = filteredOptions;
      return {
        ...category,
        options: filteredOptions,
      };
    }
  );

  const newSurveyStructureFiltered = {
    ...surveyStructureFiltered[0],
    categories: filteredCategories,
  };

  return [newSurveyStructureFiltered];
};

const DataLoading = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [questionStructure, setQuestionStructure] = useState(null);
  const [standards, setStandards] = useState(null);
  const [cultureAuditQues, setCultureAuditQues] = useState();
  const [organizationId, setOrganizationId] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [filteredStructure, setFilteredStructure] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState(null);
  const [canEdit, setCanEdit] = useState();
  const [canAccessData, setCanAccessData] = useState();
  const [accessSubfactor, setAccessSubfactor] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [employeeCategories, setEmployeeCategories] = useState();
  const [whiteLabel, setWhiteLabel] = useState();
  const navigate = useNavigate();

  const {
    survey_version,
    get_auth,
    get_survey_structure,
    surveyQuestions,
    get_standards,
    get_survey_questions,
    get_organizations,
    ambassadorId,
    get_culture_audit_reports,
    get_selectedOrg,
    nav_state,
    get_employees,
    get_employee_permission,
    get_employee_user_category,
    WhiteLabel,
    user_manager,
    get_employee_org_category,
  } = useSelector(
    (state) => ({
      get_selectedOrg: state.selectedOrg,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      ambassadorId: Number(state.auth?.ambassador_id),
      get_organizations: state.organizations,
      employeeId: Number(state.auth?.employee_id),
      get_employee_categories:
        state.employees.userEmp.employee_employee_category,
      get_auth: state.auth,
      surveyQuestions: state.surveyquestions.outcome_questions,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      get_employee_org_category: state.employee_category?.employee_org_category,
      get_employees: state.employees,
      get_survey_structure: state.surveystructure.survey_structure,
      survey_version: state.audit.survey_version,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_standards: state.survey_standard.survey_standards,
      nav_state: state.audit.nav,
      get_employee_permission: state.employee_permission,
      WhiteLabel: state.white_label?.white_label,
      user_manager: state.user_manager,
    }),
    shallowEqual
  );

  useEffect(() => {
    setIsAdmin(false);
    setCanEdit(false);
    setCanAccessData(false);
    setAccessSubfactor(false);
    if (get_employees) {
      // setUserEmployee(get_employees?.userEmp);
      setCurrentUser(get_employees?.userEmp);
      // check for admin
      if (get_employees.userEmp?.account_type === 5) {
        setIsAdmin(true);
      }

      if (get_employees.userEmp?.access_subfactor_data === 1) {
        setAccessSubfactor(true);
      }

      if (get_employees.userEmp?.survey_add_users === 1) {
        setCanEdit(true);
      }

      if (get_employees?.userEmp?.access_data_analytics === 1) {
        setCanAccessData(true);
      }
    }
  }, [get_employees]);

  useEffect(() => {
    if (
      (canEdit || canAccessData) &&
      get_employees?.userEmp &&
      !filteredCategories
    ) {
      if (isAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;
        setFilteredCategories(filterCat);
        return;
      }

      if (employeeCategories?.category?.length > 0 && !isAdmin) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => {
            return c.value_id;
          });
        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
  ]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      if (get_employee_user_category?.length > 0) {
        const currentCategories = get_employee_user_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id)
        );
        setEmployeeCategories(currentCategories);
      }
    }
  }, [get_auth?.employee_id, get_employee_user_category]);

  useEffect(() => {
    if (
      get_survey_structure &&
      get_survey_structure.length > 0 &&
      filteredCategories
    ) {
      let surveyStructureFiltered = get_survey_structure?.map((s) => {
        if (filteredCategories?.length > 0 && !isAdmin) {
          const tempCategories = [...s?.categories];
          const primaryCategories = s?.categories?.find(
            (c) => c.priority === "primary"
          );
          const replaceIndex = s?.categories?.findIndex(
            (c) => c.priority === "primary"
          );

          const filteredOptions = primaryCategories?.options.filter((so) => {
            return filteredCategories?.includes(so.id);
          });
          tempCategories[replaceIndex].options = filteredOptions;
          return { ...s, categories: tempCategories };
        } else {
          return { ...s };
        }
      });

      if (
        get_organizations.organization.services_enabled?.find((f) => f.id == 21)
          ?.enabled &&
        surveyStructureFiltered[0]
      ) {
        if (
          !surveyStructureFiltered[0].categories.find(
            (f) => f.id == "Extraversion"
          )
        ) {
          surveyStructureFiltered[0].categories = [
            ...surveyStructureFiltered[0].categories,
            ...personality_categories,
          ];
        }
      }

      if (get_employee_permission?.employee_permission?.length > 0) {
        const currentEmployeePerms =
          get_employee_permission?.employee_permission?.filter(
            (f) => f.employee_id === Number(get_auth.employee_id)
          );

        if (currentEmployeePerms?.length > 0) {
          const prioritiesToFilter = currentEmployeePerms[0]?.details?.map(
            (m) => {
              return { priority: m.name_id, value: m.value_id };
            }
          );

          // Create a new array of objects with prioritiesToFilter
          // loop through the array and add all of the values to a new array
          // with the same key as the priority.
          //  the structure should look like this:
          // [{priority: "d113fcb2-513c-4743-8e06-02534529da63", values: ["6a3a3a96-702e-4a15-9c52-fa390b202cc5"]}]
          const prioritiesToFilterStructure = prioritiesToFilter.reduce(
            (acc, curr) => {
              const { priority, value } = curr;
              const existingPriority = acc.find(
                (item) => item.priority === priority
              );
              if (existingPriority) {
                existingPriority.values.push(value);
              } else {
                acc.push({ priority, values: [value] });
              }
              return acc;
            },
            []
          );

          surveyStructureFiltered = filterSurveyStructure(
            surveyStructureFiltered,
            prioritiesToFilterStructure
          );

          setFilteredStructure(surveyStructureFiltered);
          return;
        }
      }
      setFilteredStructure(surveyStructureFiltered);
      return;
    }
  }, [
    get_survey_structure,
    filteredCategories,
    isAdmin,
    get_culture_audit_reports,
  ]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      const employeeId = Number(get_auth?.employee_id);

      dispatch({
        type: SG_GET_EMPLOYEE_USER_CATEGORIES,
        payload: `employee=${employeeId}`,
      });

      dispatch({
        type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
        payload: `organization=${get_auth?.organization_id}`,
      });
    }
  }, [get_auth]);

  useEffect(() => {
    dispatch({
      type: SG_GET_MANAGER_USERS,
    });
  }, []);

  // Set the standards
  useEffect(() => {
    const found_version = survey_version ? survey_version?.sort_order : cultureAuditQues

    if (survey_version && get_standards) {
      setStandards(
        get_standards.find((f) =>
          f.question_sort_order.includes(survey_version?.sort_order)
        ) || {}
      );
    }

    if (!survey_version && get_culture_audit_reports?.response?.length === 0) {
      setStandards({});
    }
  }, [survey_version, get_standards, get_culture_audit_reports,cultureAuditQues]);

  // Set the orgId
  useEffect(() => {
    if (Number(ambassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, ambassadorId, get_auth]);

  // Set the culture audit questions
  useEffect(() => {
    if (organizationId && get_organizations) {
      const organization = get_organizations?.[organizationId];
      let isOTP = organization?.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled;

      let survey_type = isOTP ? SURVEY_INFO.otp.name : SURVEY_INFO.audit.name;

      setCultureAuditQues(
        organization?.services_enabled &&
          organization?.services_enabled.filter(
            (sc) => sc?.name === survey_type
          )?.[0]?.question_sort_order
      );
    }
  }, [get_organizations, organizationId]);

  // Organize the survey questions
  useEffect(() => {
    if (cultureAuditQues > 0) {
      if (get_survey_questions && get_survey_questions.length > 0) {
        let SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === cultureAuditQues
          )?.[0],
        };


        if (survey_version) {
          SurveyQues = {
            ...get_survey_questions.filter(
              (sq) => sq.sort_order === survey_version.sort_order
            )?.[0],
          };
        }

        const QuesStruc = {
          title: SurveyQues?.questions?.title,
          dimensions: SurveyQues?.questions?.dimensions,
          type: SurveyQues?.questions?.type,
          type_name: SurveyQues?.questions?.type_name,
          comments: SurveyQues?.questions?.comments,
        };

        setQuestionStructure(QuesStruc);
        dispatch({
          type: SET_QUESTIONS,
          payload: QuesStruc,
        });
      }
    } else if (get_survey_questions?.[0]) {
      let SurveyQues = { ...get_survey_questions[0] };

      if (survey_version) {
        SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === survey_version.sort_order
          )?.[0],
        };
      }

      const QuesStruc = {
        title: SurveyQues?.questions?.title,
        dimensions: SurveyQues?.questions?.dimensions,
        type: SurveyQues?.questions?.type,
        type_name: SurveyQues?.questions?.type_name,
        comments: SurveyQues?.questions?.comments,
      };

      setQuestionStructure(QuesStruc);
      dispatch({
        type: SET_QUESTIONS,
        payload: QuesStruc,
      });
    }
  }, [dispatch, get_survey_questions, cultureAuditQues, survey_version]);

  // Set reportData and dates
  useEffect(() => {
    if (get_survey_structure && get_survey_structure[0]) {
      const DefState = build_default_State(get_survey_structure[0]);
      dispatch({
        type: SET_NAVIGATION,
        payload: DefState,
      });
      if (get_culture_audit_reports.response) {
        if (get_culture_audit_reports.response.length > 0) {
          let sorted_responses = get_culture_audit_reports.response.sort(
            (a, b) => b.id - a.id
          );

          let sortedCopy = [...sorted_responses];
          if (survey_version) {
            sorted_responses = sorted_responses.filter(
              (f) => f.question.sort_order === survey_version.sort_order
            );
          }
          if (checkPersonality(get_organizations)) {
            //Modify the results to include personality as a categorical variable
            sorted_responses =
              convert_personality_to_categories(sorted_responses);
            sortedCopy = convert_personality_to_categories(sortedCopy);
          }

          let most_recent;
          most_recent = get_most_recent(sorted_responses);
          let total = {};
          most_recent.responses?.map((r) => {
            let n = r.categories.length.toString();
            if (!(n in total)) {
              total[n] = 1;
            } else {
              total[n] += 1;
            }
          });

          most_recent["last"] = get_last_responses(sorted_responses);

          if (sorted_responses.length > 0 && most_recent.responses) {
            let historical_data = sort_historical_data(
              sorted_responses.reverse(),
              0
            );

            setReportData([most_recent, ...historical_data]);

            dispatch({
              type: SET_RAW_DATA,
              payload: [most_recent, ...historical_data],
            });

            dispatch({
              type: SET_DATA_DATES,
              payload: get_dates(historical_data),
            });
          }
        }
      }
    }
  }, [
    dispatch,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    survey_version,
  ]);

  // Set the filtered data
  useEffect(() => {
    if (nav_state && reportData) {
      if (Object.keys(nav_state).length > 0) {
        let filtered_data = filter_data(
          reportData,
          nav_state,
          get_survey_structure[0]
        );
        dispatch({
          type: SET_FILTERED_DATA,
          payload: filtered_data.filter_data,
        });

        dispatch({
          type: SET_FILTERED_LAST_DATA,
          payload: filtered_data.filter_data_last,
        });
      }
    }
  }, [dispatch, nav_state, reportData]);


  // Set the core data
  useEffect(() => {
    if (
      get_survey_structure?.[0] &&
      surveyQuestions &&
      surveyQuestions?.length > 0 &&
      get_standards &&
      questionStructure &&
      standards &&
      filteredCategories &&
      get_employees?.userEmp &&
      get_organizations &&
      organizationId &&
      get_auth?.employee?.account_type === 5
    ) {
      let filterHiddenCategories = filteredStructure?.[0]?.categories?.filter(
        (f) => !f.hidden
      );
      const outcomeSortOrder = get_organizations?.[
        organizationId
      ]?.styling?.survey_sequence?.find((f) => f.value === "outcome_question")
        ?.question?.sort_order;
      let outcomeQ;

      if (outcomeSortOrder) {
        outcomeQ = surveyQuestions?.find(
          (f) => f.sort_order === outcomeSortOrder
        );
      }

      const core_data = {
        noData: get_culture_audit_reports?.response?.length === 0,
        categories: [
          { ...filteredStructure?.[0], categories: filterHiddenCategories },
        ],
        questions: questionStructure,
        standards,
        surveyQuestions,
        get_standards,
        permissions: get_employees?.userEmp,
        employeeCategories: employeeCategories,
        Whitelabel: get_organizations?.[organizationId]?.styling,
        orgCategories: get_employee_org_category,
        reportData,
        outcomeQuestions: outcomeQ,
      };

      if (filterHiddenCategories) {
        dispatch({
          type: SET_CORE_DATA,
          payload: core_data,
        });
      }
    } else if (
      get_survey_structure?.[0] &&
      surveyQuestions &&
      surveyQuestions?.length > 0 &&
      get_standards &&
      questionStructure &&
      standards &&
      get_employees?.userEmp &&
      get_organizations &&
      organizationId &&
      get_auth?.employee?.account_type !== 5 &&
      user_manager?.managerUsers?.results &&
      ((canAccessData && filteredCategories && filteredStructure) ||
        !canAccessData)
    ) {
      const employeeId = Number(get_auth?.employee_id);
      // if the employeeId exists within the managers list, retrieve the object
      const employee = user_manager?.managerUsers?.results?.find(
        (f) => f.id === employeeId
      );
      // if (employee) {
      let filterHiddenCategories = filteredStructure?.[0]?.categories?.filter(
        (f) => !f.hidden
      );

      const outcomeSortOrder = get_organizations?.[
        organizationId
      ]?.styling?.survey_sequence?.find((f) => f.value === "outcome_question")
        ?.question?.sort_order;
      let outcomeQ;
      if (outcomeSortOrder) {
        outcomeQ = surveyQuestions?.find(
          (f) => f.sort_order === outcomeSortOrder
        );
      }

      const core_data = {
        noData: get_culture_audit_reports?.response?.length === 0,
        categories: [
          { ...filteredStructure?.[0], categories: filterHiddenCategories },
        ],
        questions: questionStructure,
        standards,
        surveyQuestions,
        get_standards,
        permissions: get_employees?.userEmp,
        employeeCategories: employeeCategories,
        Whitelabel: get_organizations?.[organizationId]?.styling,
        managerDetails: {
          manager: employee,
          permissions: employeeCategories?.category,
        },
        orgCategories: get_employee_org_category,
        reportData,
        outcomeQuestions: outcomeQ,
      };

      dispatch({
        type: SET_CORE_DATA,
        payload: core_data,
      });
      // }
    }
  }, [
    dispatch,
    get_survey_structure,
    questionStructure,
    standards,
    surveyQuestions,
    get_standards,
    filteredCategories,
    get_organizations,
    user_manager,
    employeeCategories,
    filteredStructure,
  ]);

  if (window.location.pathname === "/leader/audit/AI/chat") {
    return (
      <>
        <Hidden>
          <SurveyVersions />
        </Hidden>
      </>
    );
  }

  return (
    <LoadingContainer>
      <Hidden>
        <SurveyVersions />
      </Hidden>
      {/*
        the path "leader/audit/AI/chat" has its own loader, so we don't need to render the dimmer here.
       */}
      <Dimmer.Dimmable dimmed={true}>
        <Dimmer active>
          <LogAnimation />
        </Dimmer>
      </Dimmer.Dimmable>
    </LoadingContainer>
  );
};

const Hidden = styled.div`
  display: none;
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 100;
  transition: all 0.3s ease-in-out;
`;

export default DataLoading;
