import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BaseTemplate from "./BaseTemplate"; // Import your BaseTemplate
import Icon from "new_components/atoms/Icon";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors";
// Using semantic tooltip for these buttons due to complexity.
import { Popup } from "semantic-ui-react";

const LayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  height: ${({ chatStarted }) => (chatStarted ? "100%" : "calc(100vh - 40px)")};
`;

const SidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 250;
  bottom: 0;
  width: 250px;
  background-color: #fff;
  border-right: ${({ isOpen }) => (isOpen ? "1px solid #ddd" : "none")};
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translateX(${({ isOpen }) => (isOpen ? "0" : "-150%")});
  transition: transform 0.3s ease, border-right 0.2s ease;
  padding-left: 5px;
  padding-right: 10px;

  &:hover {
    overflow-y: auto; /* Show scroll on hover */
  }

  /* Custom scrollbar */
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
  z-index: 4;
`;

const ToggleButton = styled.div`
  position: fixed;
  top: 16px;
  left: 262px;
  background-color: #fff;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 0.3s ease, background-color 0.2s ease;
  &:hover {
    background-color: #ddd;
    border: 1px solid #ddd;
  }
`;

const NewChatButton = styled.div`
  position: fixed;
  top: 16px;
  left: ${({ isOpen }) => (isOpen ? "450px" : "310px")};
  background-color: #fff;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;

  transition: left 0.3s ease, background-color 0.2s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #ddd;
    border: 1px solid #ddd;
  }
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmenuSection = styled.div`
  height: 100vh;
  border-radius: 4px;
  padding: 12px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  transform: translateX(${({ isOpen }) => (isOpen ? "150px" : "0")});
  transition: transform 0.3s ease;
`;

const ChatContent = styled.div`
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
`;

const ChatHeading = styled.div`
  display: ${({ chatStarted }) => (chatStarted ? "none" : "flex")};
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const InputBox = styled.div`
  position: ${({ chatStarted }) => (chatStarted ? "fixed" : "fixed")};
  bottom: ${({ chatStarted }) => (chatStarted ? "0" : "150px")};
  left: ${({ chatStarted, sidebarOpen }) =>
    chatStarted
      ? sidebarOpen
        ? "550px"
        : "400px"
      : sidebarOpen
      ? "550px"
      : "400px"};
  width: 60%;
  transition: left 0.3s ease;
  display: flex;
  justify-content: center;
  padding: 16px;
  flex-direction: column;
  box-sizing: border-box;
  transform: translateX(${({ isOpen }) => (isOpen ? "250px" : "0")});
`;

const OverlayHide = styled.div`
  height: 20px;
  width: 100%;
  margin-bottom: -20px;
  background-color: #fff;
`;

const ChatBox = styled.div`
  position: ${({ chatStarted }) => (chatStarted ? "relative" : "fixed")};
  bottom: ${({ chatStarted }) => (chatStarted ? "150px" : "250px")};
  left: ${({ chatStarted, sidebarOpen }) =>
    chatStarted
      ? sidebarOpen
        ? "250px"
        : "0px"
      : sidebarOpen
      ? "250px"
      : "100px"};
  width: ${({ chatStarted }) => (chatStarted ? "calc(100% - 60px)" : "100%")};
  transition: left 0.3s ease;
  display: flex;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  transform: translateX(${({ isOpen }) => (isOpen ? "150px" : "0")});
`;

const ChatScrollArea = styled.div`
  flex: 1;
  padding: 16px;
  scroll-behavior: smooth; /* Smooth scrolling for better UX */
`;

const ChatTemplate = ({
  heading,
  pageTitle,
  subtitle,
  scrollable,
  chatHeading,
  chatContent,
  chatStarted,
  chatComponent,
  submenuData, // { sectionOne: JSX, sectionTwo: JSX }
  activeRoom,
  startNewChat,
  children,
  inputBox,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    if (chatStarted) {
      setIsSidebarOpen(false);
    }
  }, [activeRoom, chatStarted]);

  return (
    <BaseTemplate
      heading={!chatStarted && heading}
      pageTitle={!chatStarted && pageTitle}
      subtitle={!chatStarted && subtitle}
      scrollable={chatStarted ? false : scrollable}
    >
      <LayoutWrapper chatStarted={chatStarted}>
        <Popup
          content="Toggle sidebar"
          inverted
          position="bottom left"
          trigger={
            <ToggleButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
              <Icon
                name={ICON_SET.dashboard}
                style={{ cursor: "pointer" }}
                color={colors.neutral[500]}
              />
            </ToggleButton>
          }
        />

        <Popup
          content="New Chat"
          inverted
          position="bottom left"
          trigger={
            <NewChatButton
              isOpen={isSidebarOpen}
              onClick={() => startNewChat()}
            >
              <Icon
                name={ICON_SET.pencil}
                size={20}
                style={{ cursor: "pointer" }}
              />
            </NewChatButton>
          }
        />

        <SidebarWrapper isOpen={isSidebarOpen}>
          <SidebarContent>
            <SubmenuSection>
              {submenuData?.sectionOne /* Render first section */}
            </SubmenuSection>
          </SidebarContent>
        </SidebarWrapper>

        <ContentWrapper chatStarted={chatStarted} isOpen={isSidebarOpen}>
          <ChatScrollArea chatStarted={chatStarted}>
            <ChatHeading chatStarted={chatStarted}>
              {chatHeading && chatHeading}
            </ChatHeading>
            <ChatContent isOpen={isSidebarOpen} chatStarted={chatStarted}>
              {chatContent && chatContent}
            </ChatContent>
          </ChatScrollArea>
        </ContentWrapper>
      </LayoutWrapper>
      <ChatBox chatStarted={chatStarted} sidebarOpen={isSidebarOpen}>
        {chatComponent && chatComponent}
      </ChatBox>
      <InputBox chatStarted={chatStarted} sidebarOpen={isSidebarOpen}>
        {inputBox && inputBox}
        <OverlayHide />
      </InputBox>
    </BaseTemplate>
  );
};

ChatTemplate.propTypes = {
  heading: PropTypes.string,
  pageTitle: PropTypes.string,
  subtitle: PropTypes.string,
  onSend: PropTypes.func,
  scrollable: PropTypes.bool,
  chatHeading: PropTypes.node,
  chatContent: PropTypes.node,
  chatStarted: PropTypes.bool,
  chatComponent: PropTypes.node,
  submenuData: PropTypes.shape({
    sectionOne: PropTypes.node,
    sectionTwo: PropTypes.node,
  }),
};

ChatTemplate.defaultProps = {
  scrollable: false,
};

export default ChatTemplate;